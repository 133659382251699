.readyly-banner{
    width:100%;
    height:4rem;
    background: rgb(0, 29, 79);
    margin-bottom:1rem;
    position:fixed;
    margin:0 0 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}
.readyly-app-name{
    text-align: center;
    color:rgb(255, 255, 255);
    font-size:1.4rem;

}
.readyly-logo{
    background-image:url("https://dsdbucketimage2.s3.amazonaws.com/Readyly_Logo.png");
    height: 50%;
    width: 10%;
    background-size: contain;
    margin-left: 2rem;
    margin-top: 15px;
    background-repeat: no-repeat;
    
}
#logo-container{
    margin-left:3rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: inherit;
    cursor: pointer;
    width: 40%;
}