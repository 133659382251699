.split-button{
    background-color: white ;
    color:#152938;
    border:1px solid #152938 ; 
    font-weight: 600 ;
}
.split-button:hover{
    background-color: #152938;
    color:white;
    border:none;
}