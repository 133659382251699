.sandBoxClass {
    border: 2px solid #c4b9b9;
    width: 50vw;
    height: 65vh;
    display: flex;
    position: relative;
    border-radius: 15px 15px;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    column-gap: 1rem;
}

.dropdownContainer {
    width:100%;
    height: 10%;
    border-radius:2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
#gpt-prompts span , #temperature-bar span{
    cursor: pointer;
}
#gpt-prompts span:hover{
    transform: scale(2,2);
}
